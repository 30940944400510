/* eslint-disable camelcase */
import { LOCALES } from '../../Constants'

const LanguageCN = {
  [LOCALES.CHINESE]: {
    homePage: '主页',
    pageNotExists: '抱歉，您访问的页面不存在。',
    'editFile': 'Edit {file} and save to reload.',
    hello: 'Hello',
    demoPlaceholder: 'Placeholder sample',
    nickname: '昵称',
    'nickname-placeholder': '对外开放的名字',
    'first-name': '名',
    'last-name': '姓',
    email: '邮箱',
    'email-placeholder': '电子邮箱地址',
    'mobile-number': '手机号码',
    'mobile-number-placeholder': '手机号码, 例如 +1 232 323 232',
    language: '语言',
    'reset-password': '重置密码',
    'new-password': '新密码',
    'confirm-reset': '确定重置密码',
    password: '密码',
    'confirm-password': '确定密码',
    'confirm-password-diff': '请确定密码是不同的',
    confirm: '确认',
    'not-authorize': '您还未被授权，请登录后重试',
    'upload-area': '点击或拖拽文件到此处上传',
    dashboard: '仪表盘',
    'new-article': '新建文章',
    articles: '文章',
    unlimited: '无限制',
    storage: '存储空间',
    channels: '渠道',
    users: '用户',
    latest_year: '最近 {number} 年',
    latest_month: '最近 {number} 月',
    latest_day: '最近 {number} 天',
    visit_stats: '访问统计',
    visit_sharer: '分享访问量',
    trend_channel: '每个渠道趋势',
    visit_channel: '每个渠道访问量',
    component: '组件',
    news_article: '咨询文章',
    comment: '评价',
    media: '多媒体',
    company: '公司',
    my_company: '我的公司',
    company_info: '公司信息',
    billing: '账单',
    super_admin: '超级管理员',
    company_list: '公司列表',
    search_news: '搜索任何咨询文章...',
    Published: '已发布',
    Draft: '草稿',
    All: '全部',
    editing: '编辑中',
    loading: '加载中 ...',
    options: '选项',
    delete: '删除',
    are_you_sure: '确定要删除吗?',
    yes_delete: '是的，删除',
    notice_delete_1: '内容一旦删除',
    notice_delete_2: '您将无法重新获取',
    notice_delete_3: '您确定要这样做吗？',
    preview: '预览',
    preview_content: '预览您的内容',
    preview_content_web: '点击下面的链接在web中预览',
    view_on_web: 'web页面中预览',
    view_on_mobile: '扫下面的二维码在手机中预览',
    visit_stat: '访问统计',
    edit_history: '编辑历史',
    publish: '发布',
    're-publish': '重新发布',
    'update-success': '{text}任务已经加入执行队列。',
    'update-error': '{text}时发生错误: {message}.',
    'loading-channels-error': '加载渠道是发生错误: {message}',
    'confirm-text': '确定{text}吗?',
    'yes-text': '是的, {text}',
    'publish-alert-1': '当{text}完成时, ',
    'publish-alert-2': '您将不能取消发布.',
    'check-confirm-1': '您确定{text}这些内容吗',
    'check-confirm-2': '个渠道吗?',
    'table-name': '文章名字',
    'invalid-content': '无效内容，请刷新后重试',
    'content-update': '内容已更新',
    'error-text': '错误: {text}',
    'title-article': '文章题目',
    'content-option': '内容选项:',
    'start-create': '开始编辑文章..',
    'cover-image': '上传封面图片',
    author: '作者:',
    'author-manage': '作者由公司管理员管理',
    tags: '公司标签:',
    excerpt: '摘要',
    intro: '内容的介绍:',
    'display-footer': '在底部显示公司联络信息？',
    yes: '是',
    no: '否',
    'no-company': '您账户下没有公司，请刷新后重试',
    'need-title': '请为您的内容提供一个标题',
    'failed-create': '创建内容失败: {text}',
    'what-in-mind': '有什么新的想法? 给文章一个标题',
    continue: '继续',
    'tag-success': '标签更新成功',
    'tag-error': '更新标签时发生错误: {text}',
    // Article Delete Button
    'confirm-delete': '确定要删除{text}吗?',
    'yes-delete': '是的, 全部删除',
    'content-processed': '处理了{text}任务',
    'got-errors': '有{text}错误发生',
    'delete-them': '全部删除',
    'notice-content': '一旦删除将无法再次找回',
    // Package Notice Board
    'package-limit-reached': '用户数量配额已用完',
    'update-subscription': '请点击链接更新套餐',
    'contact-admin': '请在账户被停用前联系管理员升级套餐',
    'invalid-plan': '无效的套餐',
    'current-invalid-plan': '当前套餐无效, ',
    'package-notice': '数量已经达到了{num1}个的限制，但是您的套餐只可以使用{num2}个，',
    // Package Service
    'content-life-time': '内容的生命周期',
    'total-channel': '全部渠道',
    'total-team-member': '全部组员',
    'total-storage': '全部存储空间',
    'total-content': '全部内容',
    'hold-content': '我们将保存您发布内容多久',
    'channel-connect': '您可以链接到的渠道全部数额',

    'search-article-title': '搜索文章标题...',
    'search-user-name': '搜索用户名字...',
    free: '免费',
    'per-month': 'AUD {num} / 月',
    'is-admin': '管理员',
    'not-admin': '非管理员',
    'error-update': '更新用户是发生错误 ({text1}): {text2}',
    'user-updated': '用户 ({text}) 已更新',
    'error-remove': '删除用户是发生错误 ({text1}): {text2}',
    'user-removed': '用户 ({text}) 已删除',
    // Company
    'company-name': '公司名称',
    'upload-logo': '上传公司图片',
    'company-number': '公司号码',
    'company-number-like': '公司号码, 例如 ABN, ACN...',
    'company-slogan': '公司口号',
    'company-website': '公司网站',
    'company-url': '公司网址',
    'company-e': '公司邮箱',
    'company-email': '公司邮箱地址',
    'company-contact': '公司电话',
    'company-contact-num': '公司联系电话',
    'company-a': '公司地址',
    'company-address': '公司具体地址',
    'company-des': '公司描述',
    'company-description': '公司的具体描述...',
    'company-admin': '公司管理员',
    'content-in-bottom': '出现在文章底部的内容...',
    authors: '作者',
    'authors-contents': '作者的内容列表:',
    'tags-contents': '标签的内容列表:',
    'content-footer': '低栏内容',
    'content-in-bottom-2': '这将会出现在每篇文章的底部',

    'delete-confirm': '您确定要删除吗?',
    'len-comments': '有{num}条评论...',
    reply: '回复',

    url: '网址',
    'new-author': '创建新作者',
    'author-name': '作者名',
    name: '名字',
    link: '链接',
    'new-tag': '创建新标签',
    'new-tag-name': '新标签名字 ...',

    'setup-wizard': '公司设置向导',
    'setup-account': '设置您的账户',
    'company-detail': '公司详情',
    finish: '完成',
    ok: '确定',

    // Wizard
    'wizard-dashboard': '您可以在控制台管理所有公司、渠道和文章的信息',
    'wizard-article': '您可以在这里管理文章，删除或创建新文章',
    'wizard-channel': '您可以管理渠道信息，编辑正在使用的渠道，或者推送新文章到渠道',
    'wizard-company-admin': '公司信息和基本设置可以在这里更新',
    previous: '上一步',
    next: '下一步',
    done: '完成',
    'get-started': '从这里开始',
    channel: '渠道',
    'connect-new': '创建一个新的',
    'click-connect': '点击这里链接',
    cancel: '取消',
    connect: '连接',
    'name-channel': '{name}渠道的名字',
    'name-type': '您{type}自定义的名字',
    'url-type': '{type}的网址',
    'website-type': '{type}网站的网址, 例如: http://abc.com',
    'username-type': '{type}的用户名',
    'username-type-above': '以上{type}站点的用户名.',
    'password-type': '{type}的密码',
    'password-type-above': '以上{type}站点的密码.',
    'channel-connected': '渠道连接成功',
    'channel-connected-error': '连接时发生错误: {message}',
    'new-channel': '新建渠道',
    main: '主要',
    'not-main': '非主要',
    valid: '有效',
    invalid: '无效',
    'channel-valid': '渠道有效',
    'channel-invalid': '渠道无效，不能被使用',
    'error-getting-channel': '获取渠道信息出错: {message}.',
    'channel-deleted': '遇到{name}已被删除',
    'error-channel-delete': '删除渠道{name}发生错误: {message}.',
    'channel-updated': '渠道{name}已被更新',
    'error-channel-update': '更新渠道{name}时发生错误: {message}.',
    'is-required': '是必须的',
    'sync-status': '同步状态',
    date: '日期',
    'total-items': '共有{total}项',
    'user-update-success': '用户信息更新成功',
    'back-to-login': 'Back to login',
    unPublishConfirm: '您即将取消发布此内容。您确定要继续吗？',
    unPublish: '取消发布',
    upgrade: '升级',
    confirmingLogout: '您确定要登出吗？',
    gTranslateFlag: '开启谷歌翻译？',
    gTranslateFlagMsg: '开启谷歌翻译以后，文章会被自动翻译',
    createAuthor: '新建作者',
    createAuthorMsg: '如果找不到对应的作者，可以新建',
    companyAuthorSaved: '作者 "{authorName}" 创建成功.',
    companyAuthorDeleted: '作者成功删除',
    createCompanyAuthor: '新建作者',
    updateCompanyAuthor: '更改作者',
    save: '保存',
    fieldIsRequired: '{fieldName}是必填项',
    invalidUrl: '{fieldName}不是正常的URL',
    ErrorOccurred: '有错误',
    'more-options': '更多选项',
  },
}

export default LanguageCN
