/* eslint-disable camelcase,quotes*/
import { LOCALES } from '../../Constants'

const LanguageEN = {
  [LOCALES.ENGLISH]: {
    homePage: 'Home Page',
    pageNotExists: 'Sorry, the page you visited does not exist.',
    editFile: 'Edit {file} and save to reload.',
    hello: 'Hello',
    demoPlaceholder: 'Placeholder sample',
    nickname: 'Nickname',
    'nickname-placeholder': 'The name that will shown to public',
    'first-name': 'First Name',
    'last-name': 'Last Name',
    email: 'Email',
    'email-placeholder': 'The email address',
    'mobile-number': 'Mobile Number',
    'mobile-number-placeholder': 'Mobile number, like +1 232 323 232',
    language: 'Language',
    'reset-password': 'Reset Password',
    'new-password': 'New Password',
    'confirm-reset': 'Confirm Reset',
    password: 'Password',
    'confirm-password': 'Confirm Password',
    'confirm-password-diff': 'Confirmed password is different',
    confirm: 'Confirm',
    'not-authorize': 'You are not authorize yet, please login and try again',
    'upload-area': 'Click or drag file to this area to upload',
    dashboard: 'Dashboard',
    'new-article': 'New Article',
    articles: 'Articles',
    unlimited: 'Unlimited',
    storage: 'Storage',
    channels: 'Channels',
    users: 'Users',
    latest_year: 'Latest {number} year',
    latest_month: 'Latest {number} month',
    latest_day: 'Latest {number} day',
    visit_stats: 'Visit Stats since',
    visit_sharer: 'Visits Per Sharer',
    trend_channel: 'Trend Per Channel',
    visit_channel: 'Visits Per Channel',
    component: 'Components',
    news_article: 'News/Articles',
    comment: 'Comments',
    media: 'Media',
    company: 'Company',
    my_company: 'My Company',
    company_info: 'Company Info',
    billing: 'Billings',
    super_admin: 'Super Admin',
    company_list: 'Company List',
    search_news: 'search news/articles...',
    Published: 'Published',
    Draft: 'Draft',
    All: 'All',
    editing: 'Editing',
    loading: 'loading ...',
    options: 'Options',
    delete: 'Delete',
    are_you_sure: 'Are you sure?',
    yes_delete: 'Yes, delete',
    notice_delete_1: 'Once this content has been deleted',
    notice_delete_2: `you won't be able to get it back anymore`,
    notice_delete_3: 'Are you sure you want to do this?',
    preview: 'Preview',
    preview_content: 'Preview your content',
    preview_content_web: 'If you want to preview your content from web, click the link below',
    view_on_web: 'View on Web',
    view_on_mobile: 'If you want to preview it on your mobile, scan the QrCode below',
    visit_stat: 'Visit Stats',
    edit_history: 'Edit History',
    publish: 'Publish',
    're-publish': 'Re-Publish',
    'update-success': 'Content added to the queue for {text}ing.',
    'update-error': 'Error when {text}ing: {message}.',
    'loading-channels-error': 'Error when loading channels: {message}',
    'confirm-text': 'Confirm {text}?',
    'yes-text': 'Yes, {text} it',
    'publish-alert-1': 'Once this content has been {text}, ',
    'publish-alert-2': `you won't be able to un-publish this content anymore.`,
    'check-confirm-1': 'Are you sure you want {state.publishText} the content',
    'check-confirm-2': 'channels?',
    'table-name': 'Name',
    'invalid-content': 'Invalid content, please refresh this page and try again.',
    'content-update': 'Content updated.',
    'error-text': 'Error: {text}',
    'title-article': 'Title of the article',
    'content-option': 'Options for your content:',
    'start-create': 'Start to create something awesome here..',
    'cover-image': 'Cover Image',
    author: 'Author:',
    'author-manage': 'Authors are managed via company admin',
    tags: 'Tags:',
    excerpt: 'Excerpt',
    intro: 'Introduction of your content:',
    'display-footer': 'Display Content Footer?',
    yes: 'Yes',
    no: 'No',
    'no-company': 'No company found for your account, refresh your page and try again.',
    'need-title': 'Please provide a title for your content.',
    'failed-create': 'Failed to create content: {text}',
    'what-in-mind': 'What in your mind? The title for your new article',
    continue: 'Continue',
    'tag-success': 'Tags updated successfully.',
    'tag-error': 'Error when updating tag: {text}',
    // Article Delete Button
    'confirm-delete': 'Are you sure to delete {text}?',
    'yes-delete': 'Yes, delete all',
    'content-processed': '{text} content(s) processed.',
    'got-errors': 'Got {text} error(s)',
    'delete-them': 'Delete Them',
    'notice-content': `Once these {text} contents have been deleted, you won't be able to get them back anymore.`,
    // Package Notice Board
    'package-limit-reached': 'Package limit reached',
    'update-subscription': 'please click here update your subscription',
    'contact-admin': "please contact your admin to upgrade before your account's suspension.",
    'invalid-plan': 'Invalid Plan Found',
    'current-invalid-plan': 'The current plan is invalid, ',
    'package-notice': 'has reached limit of {num1}, but only {num2} is included in your package, ',
    // Package Service
    'content-life-time': 'Content life time',
    'total-channel': 'Total channels',
    'total-team-member': 'Total team members',
    'total-storage': 'Total storage',
    'total-content': 'Total contents',
    'hold-content': 'How long we will hold your published content.',
    'channel-connect': 'Total number of channels you can connect with',

    'search-article-title': 'search article title...',
    'search-user-name': 'search user by name...',
    free: 'free',
    'per-month': 'AUD {num} / Month',
    'is-admin': 'Is Admin',
    'not-admin': 'Not Admin',
    'error-update': 'Error Ocurred when updating User ({text1}): {text2}',
    'user-updated': 'User ({text}) updated.',
    'error-remove': 'Error Ocurred when removing User ({text1}): {text2}',
    'user-removed': 'User ({text}) removed.',
    // Company
    'company-name': 'Name of the company',
    'upload-logo': 'Upload Company Logo',
    'company-number': 'Company Number',
    'company-number-like': 'Company Number, like ABN, ACN...',
    'company-slogan': 'Company Slogan',
    'company-website': 'Company Website',
    'company-url': 'The Url of the company',
    'company-e': 'Company Email',
    'company-email': 'The email of the company',
    'company-contact': 'Company Contact Number',
    'company-contact-num': 'The contact number of the company',
    'company-a': 'Company Address',
    'company-address': 'The address of the company',
    'company-des': 'Company Description',
    'company-description': 'Description for your company...',
    'company-admin': 'Company Admin',
    'content-in-bottom': 'The content that will displayed at the bottom of each article...',
    authors: 'Authors',
    'authors-contents': 'List of authors for your contents:',
    'tags-contents': 'List of tags for your contents:',
    'content-footer': 'Content footer',
    'content-in-bottom-2': 'It will be displayed at the bottom of every article.',

    'delete-confirm': 'Are you sure delete this?',
    'len-comments': '{num} comments...',
    reply: 'Reply',

    url: 'The url',
    'new-author': 'New Author',
    'author-name': 'Author Name',
    name: 'Name',
    link: 'Link',
    'new-tag': 'New Tag',
    'new-tag-name': 'New Tag Name ...',

    'setup-wizard': 'Company Setup Wizard',
    'setup-account': 'Set up your account',
    'company-detail': 'Company Detail',
    finish: 'Finish',
    ok: 'OK',

    // Wizard
    'wizard-dashboard': 'You can go to dashboard to manage all your company, channel and article details.',
    'wizard-article': 'You can manage the articles here, delete or create new articles.',
    'wizard-channel':
      'You can manage your channels details, edit the using ones or create the new article for pushing articles',
    'wizard-company-admin': 'The company details and basic setting can be updated here.',
    previous: 'Previous',
    next: 'Next',
    done: 'Done',
    'get-started': 'Get Started',
    channel: 'channel',
    'connect-new': 'Connecting a new ',
    'click-connect': 'click here to connect a ',
    cancel: 'Cancel',
    connect: 'Connect',
    'name-channel': 'The name of your {name} channel',
    'name-type': 'Your own name for your {type} channel.',
    'url-type': 'The url of {type} site',
    'website-type': 'The url of the {type} website, ie: http://abc.com',
    'username-type': 'The username of {type} site',
    'username-type-above': 'The username for the {type} site above.',
    'password-type': 'The password of {type} site',
    'password-type-above': 'The password for the {type} site above.',
    'channel-connected': 'Channel connected successfully.',
    'channel-connected-error': 'Error when connecting: {message}',
    'new-channel': 'New Channel',
    main: 'Main',
    'not-main': 'Not Main',
    valid: 'Valid',
    invalid: 'Invalid',
    'channel-valid': 'Channel is valid',
    'channel-invalid': 'Channel is invalid, it can not be used',
    'error-getting-channel': 'Error when getting channels: {message}.',
    'channel-deleted': 'Channel {name} deleted.',
    'error-channel-delete': 'Error when deleting Channel {name}: {message}.',
    'channel-updated': 'Channel {name} updated.',
    'error-channel-update': 'Error when updating Channel {name}: {message}.',
    'is-required': 'is required',
    'sync-status': 'Sync Status',
    date: 'Date',
    'total-items': 'Total {total} items',
    'user-update-success': 'User info updated success',
    'back-to-login': 'Back to login',
    unPublishConfirm: 'You are about to unPublish this content. Are you sure to continue?',
    upgrade: 'Upgrade',
    confirmingLogout: 'are you sure to logou？',
    gTranslateFlag: 'Enable Google Translate?',
    gTranslateFlagMsg: 'After enable GTranslate，Articles will be automatically translated by GTranslate',
    createAuthor: 'Create New Author',
    createAuthorMsg: 'Creating a new author, when not found.',
    companyAuthorSaved: 'Author "{authorName}" Saved',
    companyAuthorDeleted: 'Author Deleted',
    createCompanyAuthor: 'Create Author',
    updateCompanyAuthor: 'Update Author',
    save: 'Save',
    fieldIsRequired: '{fieldName} is required',
    invalidUrl: '{fieldName} is invalid URL',
    ErrorOccurred: 'Error Occurred',
    'more-options': 'More Options',
  },
}

export default LanguageEN
